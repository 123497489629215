import type { Map, SymbolLayer } from 'mapbox-gl';

import { nameOfLayerForMinimizedPois, nameOfLayerForPiers } from '../constants';

export const getPoiLayers = (map?: Map) => {
  return (
    (map
      ?.getStyle()
      ?.layers.filter(
        (layer) =>
          layer.type === 'symbol' &&
          layer.id !== nameOfLayerForMinimizedPois &&
          layer.id !== nameOfLayerForPiers,
      ) as SymbolLayer[]) || []
  );
};

export const createMarkerLayer = (
  id: string,
  source: string,
  iconImage: string,
  floorId: string,
): SymbolLayer => {
  return {
    id,
    type: 'symbol',
    source,
    minzoom: 0,
    maxzoom: 24,
    layout: {
      'icon-image': iconImage,
      'icon-size': 2,
      'icon-text-fit': 'none',
      'icon-anchor': 'center',
      'icon-allow-overlap': false,
      'text-field': ['get', 'name'],
      'text-offset': [1.2, 0],
      'text-anchor': 'left',
      'text-size': 36,
      'text-justify': 'right',
      'text-letter-spacing': 0,
      'text-max-width': 100,
      'text-optional': true,
      'text-rotation-alignment': 'viewport',
      visibility: 'visible',
    },
    paint: {
      'icon-opacity': [
        'case',
        ['==', ['get', 'floor', ['get', 'location', ['properties']]], floorId],
        1,
        0.4,
      ],
      'text-halo-color': '#ffffff',
      'text-halo-width': 2,
    },
  };
};
