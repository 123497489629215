import type GeoJSON from 'geojson';
import type {
  GeoJSONSource,
  LngLatBoundsLike,
  MapboxOptions,
  Marker,
  SymbolLayer,
} from 'mapbox-gl';
import mapboxgl, { Map } from 'mapbox-gl';

import type { Poi } from '../../types/poi';
import type {
  CenterMapToYouAreHereMarkerProps,
  MapCurrentPosition,
  UpdateYouAreHereMarkerLocationProps,
} from '../../types/provider';

import {
  MARKERS_LAYER,
  MARKERS_SOURCE,
  MARKER_ICON_IMAGE_NAME,
} from './constants';
import { getBounds, getPadding } from './helpers/bounds';
import { DEFAULT_CONFIG, getConfig } from './helpers/config';
import { addFpsControl } from './helpers/fpsControl';
import { fetchSvg } from './helpers/image';
import { createMarkerLayer, getPoiLayers } from './helpers/layers';
import {
  createYouAreHereMarkerElement,
  getMarkerCoordinates,
} from './helpers/markers';
import { applyCustomStyling, applyResponsiveStyling } from './helpers/styling';
import MapPin from './schiphol-map-pin.svg';

export class MapboxService {
  map: Map | undefined;
  mapRef: HTMLElement | undefined;
  config: MapboxOptions = DEFAULT_CONFIG as MapboxOptions;
  currentFloorId = '';
  currentBubbleId: string | undefined;
  youAreHereMarker: Marker | undefined;
  originalLayers: SymbolLayer[] = [];
  activeFeatures: GeoJSON.Feature<GeoJSON.Geometry>[] = [];
  markerIconImage: HTMLImageElement | undefined;

  constructor({ accessToken }: { accessToken: string }) {
    mapboxgl.accessToken = accessToken;
    this.getPoiIdFromMapMouseEvent = this.getPoiIdFromMapMouseEvent.bind(this);
    this.updateYouAreHereMarkerElement =
      this.updateYouAreHereMarkerElement.bind(this);
    this.removeYouAreHereMarker = this.removeYouAreHereMarker.bind(this);
    this.updateYouAreHereMarkerLocation =
      this.updateYouAreHereMarkerLocation.bind(this);
    this.centerMapToYouAreHereMarker =
      this.centerMapToYouAreHereMarker.bind(this);
  }

  init({
    element,
    currentLocation,
  }: {
    element: HTMLDivElement;
    currentLocation: MapCurrentPosition;
  }): Promise<Map | undefined> {
    return new Promise((resolve, error) => {
      this.mapRef = element;
      this.config = getConfig({ element, currentLocation });
      this.currentFloorId = currentLocation.floor;
      this.currentBubbleId = currentLocation.bubble;
      this.map = new Map(this.config);

      addFpsControl(this.map);

      this.map.on('load', async () => {
        this.map?.touchZoomRotate.disableRotation();
        this.originalLayers = getPoiLayers(this.map);
        resolve(this.map);
      });

      this.map.on('style.load', async () => {
        await this.setupMarkerLayer();
        this.renderFeaturesOnMap();
        applyCustomStyling(this.map);
      });

      this.map.on('resize', () => {
        applyResponsiveStyling(this.map);
      });
    });
  }

  async getMarkerIconImage() {
    if (this.markerIconImage) {
      return this.markerIconImage;
    }

    const image = (await fetchSvg(MapPin.src)) as HTMLImageElement;

    this.markerIconImage = image;

    return image;
  }

  async setupMarkerLayer() {
    if (this.map?.getLayer(MARKERS_LAYER)) {
      this.map?.removeLayer(MARKERS_LAYER);
    }

    if (this.map?.getSource(MARKERS_SOURCE)) {
      this.map?.removeSource(MARKERS_SOURCE);
    }

    const image = await this.getMarkerIconImage();

    if (!this.map?.hasImage(MARKER_ICON_IMAGE_NAME)) {
      this.map?.addImage(MARKER_ICON_IMAGE_NAME, image);
    }

    this.map?.addSource(MARKERS_SOURCE, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });

    const markerLayer = createMarkerLayer(
      MARKERS_LAYER,
      MARKERS_SOURCE,
      MARKER_ICON_IMAGE_NAME,
      this.currentFloorId,
    );
    this.map?.addLayer(markerLayer);
  }

  // eslint-disable-next-line
  getPoiIdFromMapMouseEvent(event: any) {
    if (!event) return;
    const [feature] = this.map?.queryRenderedFeatures(event.point) || [];

    if (!feature || feature.layer?.type !== 'symbol') {
      return;
    }

    return feature?.properties?.id;
  }

  get isLoaded() {
    return !!this.map?.loaded();
  }

  getCurrentFloorId() {
    return this.currentFloorId;
  }

  setFloor(floorId: string) {
    const currentFloor = this.getCurrentFloorId();
    if (currentFloor !== floorId) {
      this.currentFloorId = floorId;
      this.map?.setStyle(floorId);
    }
  }

  setBounds(pois?: Poi[]) {
    const youAreHereCoordinates = getMarkerCoordinates(this.youAreHereMarker);

    if (!youAreHereCoordinates) {
      return;
    }

    if (!pois?.length) {
      const bounds = getBounds([youAreHereCoordinates]);
      return this.adjustCameraByBounds(bounds);
    }

    const poiCoordinates = pois.map((poi) => poi.position);
    const bounds = getBounds([youAreHereCoordinates, ...poiCoordinates]);

    return this.adjustCameraByBounds(bounds);
  }

  adjustCameraByBounds(bounds: LngLatBoundsLike, options?: any) {
    this.map?.fitBounds(bounds, {
      padding: getPadding(),
      bearing: this.config.bearing,
      pitch: this.config.pitch,
      ...options,
    });
  }

  renderFeaturesOnMap() {
    if (!this.map) {
      return undefined;
    }

    // It could be that the map is already being unloaded. There is no good way to check it
    // this.map?.isStyleLoaded() is not reliable
    try {
      (this.map.getSource(MARKERS_SOURCE) as GeoJSONSource)?.setData({
        type: 'FeatureCollection',
        features: this.activeFeatures,
      });
    } catch {}
  }

  createFeatureFromPoi(poi: Poi): GeoJSON.Feature<GeoJSON.Geometry> {
    return {
      type: 'Feature',
      properties: poi,
      geometry: {
        type: 'Point',
        coordinates: [poi.position.lon, poi.position.lat],
      },
    };
  }

  async updateYouAreHereMarkerElement(element: HTMLDivElement) {
    const latLng = this.youAreHereMarker?.getLngLat();
    const [lng, lat] = (this.config.center as [number, number]) || [0, 0];
    const { lat: latitude, lng: longitude } = latLng || { lat, lng };

    const options = {
      latitude,
      longitude,
      heading: this.youAreHereMarker?.getRotation() || this.config.bearing || 0,
    };

    if (this.youAreHereMarker) {
      this.youAreHereMarker.remove();
    }

    this.youAreHereMarker = createYouAreHereMarkerElement(element, options);

    if (this.map) {
      this.youAreHereMarker?.addTo(this.map);
    }
  }

  async removeYouAreHereMarker() {
    if (this.youAreHereMarker) {
      this.youAreHereMarker.remove();
    }
    return;
  }

  async updateYouAreHereMarkerLocation({
    longitude,
    latitude,
  }: UpdateYouAreHereMarkerLocationProps) {
    this?.youAreHereMarker?.setLngLat({ lng: longitude, lat: latitude });
  }

  async centerMapToYouAreHereMarker(
    { heading }: CenterMapToYouAreHereMarkerProps = { heading: null },
  ) {
    const coordinates = getMarkerCoordinates(this.youAreHereMarker);

    if (!coordinates) {
      return;
    }

    this.map?.flyTo({
      center: coordinates,
      bearing: heading ?? this.config.bearing,
      zoom: this.config.zoom,
    });

    return;
  }

  addMarker(poi: Poi) {
    if (!this.map || !this.mapRef) {
      return undefined;
    }

    const selectedFeature = this.createFeatureFromPoi(poi);

    if (!selectedFeature) {
      return;
    }

    this.activeFeatures.push(selectedFeature);
    this.renderFeaturesOnMap();

    return;
  }

  addMarkers(pois: Poi[]) {
    if (!this.map || !this.mapRef) {
      return undefined;
    }

    pois.forEach((poi) => {
      const selectedFeature = this.createFeatureFromPoi(poi);
      if (selectedFeature) {
        this.activeFeatures.push(selectedFeature);
      }
    });

    this.renderFeaturesOnMap();

    return;
  }

  removeMarker(poiId: string) {
    this.activeFeatures = this.activeFeatures.filter(
      (feature) => feature.properties?.id !== poiId,
    );
    this.renderFeaturesOnMap();
  }

  clearActiveMarkers() {
    this.activeFeatures = [];
    this.renderFeaturesOnMap();
  }

  resetView(clearMarkers?: boolean) {
    const youAreHereCoordinates = getMarkerCoordinates(this.youAreHereMarker);

    // Reset layers to original filters
    // We need to add some extra checks to make sure the map is loaded
    // This will avoid the "Style is not done loading" error thrown by Mapbox
    // usually triggered when switching languages quickly
    if (this.map?.isStyleLoaded()) {
      this.originalLayers.forEach((layer) => {
        this.map?.setFilter(layer.id, layer.filter);
      });
    } else {
      this.map?.on('style.load', () => {
        this.originalLayers.forEach((layer) => {
          // Check if the layer exists
          if (this.map?.getLayer(layer.id)) {
            this.map?.setFilter(layer.id, layer.filter);
          }
        });
      });
    }

    if (clearMarkers) {
      this.clearActiveMarkers();
    }

    this.map?.flyTo({
      center: youAreHereCoordinates,
      bearing: this.config.bearing,
      pitch: this.config.pitch,
      zoom: this.config.zoom,
    });
  }
}
