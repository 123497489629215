import { Breakpoint } from '../types/breakpoint';

export const getBreakpoint = (): Breakpoint => {
  const screenWidth = typeof window === 'undefined' ? 0 : window.innerWidth;
  return (
    (Object.keys(Breakpoint)
      .filter((key) => isNaN(Number(key)))
      .map((key) =>
        Number((Breakpoint as unknown as Record<string, string>)[key]),
      )
      .sort((a, b) => b - a)
      .find((value) => screenWidth >= value) as Breakpoint) || Breakpoint.SMALL
  );
};
