import { LngLatBounds, LngLatBoundsLike } from 'mapbox-gl';
import { Poi } from '../../../types/poi';

type PoiCoordinates = Pick<Poi['position'], 'lat' | 'lon'>;

export const getBounds = (coordinates: PoiCoordinates[]): LngLatBoundsLike => {
  const { north, south, east, west } = coordinates.reduce(
    (acc, { lon, lat }) => {
      return {
        ...acc,
        north: Math.max(lat, acc.north),
        south: Math.min(lat, acc.south),
        east: Math.max(lon, acc.east),
        west: Math.min(lon, acc.west),
      };
    },
    {
      north: coordinates[0].lat,
      south: coordinates[0].lat,
      east: coordinates[0].lon,
      west: coordinates[0].lon,
    },
  );

  return new LngLatBounds([west, south, east, north]);
};

export const getPadding = () => {
  if (typeof window !== 'undefined') {
    const { innerWidth, innerHeight } = window;

    return {
      top: Math.round(innerHeight * 0.15),
      right: Math.round(innerWidth * 0.18),
      bottom: Math.round(innerHeight * 0.33),
      left: Math.round(innerWidth * 0.18),
    };
  }
  return;
};
