import MapPin from '../schiphol-map-pin.svg';

// Creates image from SVG data URI
const dataUri2image = (dataUri: string) =>
  new Promise((resolve, reject) => {
    const img = new Image(MapPin.width / 7, MapPin.height / 7);
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = dataUri;
  });

// Fetch SVG and convert to Base64 data URI
export const fetchSvg = (url: string) =>
  fetch(url)
    .then((response) => response.text())
    .then(
      (svg) => `data:image/svg+xml;charset=utf-8;base64,${window.btoa(svg)}`,
    )
    .then(dataUri2image);
