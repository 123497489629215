import { Map } from 'mapbox-gl';
import { getLocalStorageConfig } from '../../../../../lib/get-local-storage-config';
// eslint-ignore-next-line
// @ts-ignore
import { FPSControl } from 'mapbox-gl-fps/lib/MapboxFPS.min';
import { LocalStorageConfigName } from '../../../../../enums/localStorageConfig';

export const addFpsControl = (map: Map) => {
  const isEnabled = getLocalStorageConfig(LocalStorageConfigName.FPS_MAP);
  if (isEnabled) {
    const fpsControl = new FPSControl();
    map.addControl(fpsControl, 'top-left');
  }
};
