import mapboxgl from 'mapbox-gl';

export const ZOOM = {
  max: 21,
  min: 16,
  default: 19,
  absoluteMin: 16,
};

export const MARKER_ICON_IMAGE_NAME = 'map-pin';
export const MARKERS_LAYER = 'markers-layer';
export const MARKERS_SOURCE = 'markers-source';

export const MAX_BOUNDS: mapboxgl.LngLatBoundsLike = [
  [4.70490838983352, 52.297105508461385],
  [4.793005219597369, 52.328837052950654],
];

export const nameOfLayerForMinimizedPois = 'minimized-pois';
export const nameOfLayerForPiers = '25/25_Pie';
export const categoriesToMinimize = [
  '30_AcHoDe',
  '30_Bar',
  '30_FoCoBa',
  '30_Hot',
  '30_Res',
  '60_AiLi',
  '90_Bank',
  '90_CaMa',
  '90_BiATM',
  '90_HaAWe',
  '90_ShACo',
  '100_BoSt',
  '100_ChACo',
  '100_El',
  '100_FaAAc',
  '100_GiASo',
  '100_JeAWa',
  '100_Oth',
  '100_PeACo',
  '100_TrRe',
  '100_LiADe',
  '900_BoEl',
  '991_De',
  '992_LiChSw',
  '993_CoPr',
  '994_Be',
];

export const filterForMinimizedCategories = [
  'all',
  ['in', 'poi_subcategory'].concat(categoriesToMinimize),
  ['==', 'airport_side_en', 'After security'],
];

export const afterSecurityFilter = ['==', 'airport_side_en', 'After security'];
