import { MapboxOptions, Marker } from 'mapbox-gl';
import { Breakpoint } from '../../../../../types/breakpoint';
import { MapCurrentPosition } from '../../../types/provider';
import { getBreakpoint } from '../../../../../lib/responsive';
import { MAX_BOUNDS, ZOOM } from '../constants';

const zoomByBreakpoint = {
  [Breakpoint.FOUR_K]: 19,
  [Breakpoint.LARGE]: 19,
  [Breakpoint.MEDIUM]: 17,
  [Breakpoint.SMALL]: 17,
};

const getDefaultZoom = () => {
  const breakpoint = getBreakpoint();
  return zoomByBreakpoint[breakpoint] || ZOOM.default;
};

export const DEFAULT_CONFIG: Partial<MapboxOptions> = {
  attributionControl: false,
  maxBounds: MAX_BOUNDS,
  maxZoom: ZOOM.max,
  minZoom: ZOOM.min,
  zoom: ZOOM.default,
  pitch: 0,
  fadeDuration: 100,
  antialias: false,
  touchPitch: false,
  dragRotate: false,
  touchZoomRotate: true,
};

export const getConfig = ({
  element,
  currentLocation,
}: {
  element: HTMLDivElement;
  currentLocation: MapCurrentPosition;
}): MapboxOptions => {
  return {
    ...DEFAULT_CONFIG,
    zoom: getDefaultZoom(),
    container: element,
    style: currentLocation.floor,
    center: [currentLocation.longitude, currentLocation.latitude],
    bearing: currentLocation.rotation,
  };
};
