import { Marker } from 'mapbox-gl';
import { UpdateYouAreHereMarkerLocationProps } from '../../../types/provider';

export const createYouAreHereMarkerElement = (
  element: HTMLDivElement,
  { latitude, longitude }: UpdateYouAreHereMarkerLocationProps,
) => {
  const youAreHereMarkerElement = document.createElement('div');
  youAreHereMarkerElement.innerHTML = element.innerHTML || '';

  return new Marker(youAreHereMarkerElement).setLngLat([longitude, latitude]);
};

export const getMarkerCoordinates = (marker?: Marker) => {
  if (!marker) {
    return undefined;
  }

  const latLng = marker.getLngLat();

  return { lat: latLng.lat, lon: latLng.lng };
};
