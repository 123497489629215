import type { Map, SymbolLayer } from 'mapbox-gl';

import { isPublic } from '@/lib/environment';

import { getBreakpoint } from '../../../../../lib/responsive';
import { Breakpoint } from '../../../../../types/breakpoint';

import { getPoiLayers } from './layers';

const DEFAULT_ICON_SIZE = 1;
const DEFAULT_TEXT_SIZE = 12;
const YELLOW_ICON_PADDING = [0, 16, 0, 16];

const getResponsiveSizes = () => {
  const breakpointsMap = isPublic
    ? {
        [Breakpoint.FOUR_K]: { textMultiplier: 1, iconMultiplier: 0.7 },
        [Breakpoint.LARGE]: { textMultiplier: 1.2, iconMultiplier: 1 },
        [Breakpoint.MEDIUM]: { textMultiplier: 1.2, iconMultiplier: 1 },
        [Breakpoint.SMALL]: { textMultiplier: 1, iconMultiplier: 0.7 },
      }
    : {
        [Breakpoint.FOUR_K]: { textMultiplier: 3, iconMultiplier: 2 },
        [Breakpoint.LARGE]: { textMultiplier: 2, iconMultiplier: 1.5 },
        [Breakpoint.MEDIUM]: { textMultiplier: 1.3, iconMultiplier: 1.2 },
        [Breakpoint.SMALL]: { textMultiplier: 1, iconMultiplier: 1 },
      };
  const breakpoint = getBreakpoint();
  const { textMultiplier, iconMultiplier } = breakpointsMap[breakpoint];
  return {
    text: DEFAULT_TEXT_SIZE * textMultiplier,
    icon: DEFAULT_ICON_SIZE * iconMultiplier,
  };
};

const setTextSize = (map: Map, layer: SymbolLayer) =>
  map.setLayoutProperty(layer.id, 'text-size', getResponsiveSizes().text);

const setYellowIconSize = (map: Map, layer: SymbolLayer) => {
  if (layer.layout?.['icon-image'] === 'yellow') {
    map.setLayoutProperty(
      layer.id,
      'icon-text-fit-padding',
      YELLOW_ICON_PADDING,
    );
  }
};

const setIconSize = (map: Map, layer: SymbolLayer) => {
  if (layer.layout?.['icon-image'] !== 'yellow') {
    map.setLayoutProperty(layer.id, 'icon-size', getResponsiveSizes().icon);
  }
};

const setOverlap = (map: Map, layer: SymbolLayer) => {
  map.setLayoutProperty(layer.id, 'icon-allow-overlap', false);
  map.setLayoutProperty(layer.id, 'text-allow-overlap', false);
};

export const applyCustomStyling = (map?: Map) => {
  if (!map) {
    return;
  }

  const poiLayers = getPoiLayers(map);

  poiLayers.forEach((layer) => {
    setTextSize(map, layer);
    setIconSize(map, layer);
    setYellowIconSize(map, layer);
    setOverlap(map, layer);
  });
};

export const applyResponsiveStyling = (map?: Map) => {
  if (!map) {
    return;
  }

  const poiLayers = getPoiLayers(map);
  poiLayers.forEach((layer) => {
    setTextSize(map, layer);
    setIconSize(map, layer);
  });
};
