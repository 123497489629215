import { Poi as RawPoi } from 'pxp-content-api-schema/types';
import { Poi } from '../../types/poi';

export const normalizePoi = (data: RawPoi): Poi => {
  return {
    ...data,
    defaultName: data.name,
    bubble: data.location?.bubble,
    areaGroups: data.location?.areaGroups,
    floor: data.location?.floor || '',
    categories: data.categories,
    tags: data.tags,
    position: {
      // distanceInMeters: 0;
      // distanceInSeconds: 0;
      lat: Number(data.location?.coordinates?.latitude || 0),
      lon: Number(data.location?.coordinates?.longitude || 0),
    },
  };
};
