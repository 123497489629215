import type { MapEventType } from 'mapbox-gl';

import type {
  MapGetPoisProps,
  MapProviderAdapter,
  MapProviderAdapterConstructor,
  MapResetViewOptions,
} from '../../types/provider';

import { MapboxService } from './mapbox-service';
import { normalizePoi } from './normalize';

export const MapboxAdapter: MapProviderAdapterConstructor = (
  options,
  poiService,
) => {
  const mapboxService = new MapboxService({ accessToken: options.hash || '' });

  const init: MapProviderAdapter['init'] = async ({
    element,
    currentLocation,
    locale,
  }) => {
    poiService.updateNormalizer(normalizePoi);
    await Promise.all([
      poiService.fetchAll({ locale }),
      mapboxService.init({ element, currentLocation }),
    ]);
  };

  const goToPoi: MapProviderAdapter['goToPoi'] = async (id, options) => {
    const poi = poiService.getById(id);

    if (!poi) {
      return;
    }

    mapboxService.clearActiveMarkers();
    mapboxService.setFloor(poi.floor);
    mapboxService.addMarker(poi);
    mapboxService.setBounds([poi]);

    return poi;
  };

  const search: MapProviderAdapter['search'] = async (
    { query, categories, defaultCategories },
    { limit },
  ) => {
    const bubbleId = mapboxService.currentBubbleId;
    return poiService.search(
      { query, categories, bubbleId, defaultCategories },
      { limit },
    );
  };

  const fetchPoiById: MapProviderAdapter['fetchPoiById'] = (id) => {
    return poiService.fetchById(id);
  };

  const getPoiById: MapProviderAdapter['getPoiById'] = async (id) => {
    return poiService.getById(id);
  };

  const getPois = async ({ locale }: MapGetPoisProps) => {
    return poiService.fetchAll({ locale });
  };

  const resetView: MapProviderAdapter['resetView'] = async (
    options: MapResetViewOptions = {},
  ) => {
    const { clearMarkers } = options;
    mapboxService.resetView(clearMarkers);
  };
  const terminate: MapProviderAdapter['terminate'] = async () => {
    try {
      mapboxService.map?.remove();
    } catch (e) {}
  };

  const addEventListener: MapProviderAdapter['addEventListener'] = (
    event,
    cb,
  ) => {
    mapboxService.map?.on(event as MapEventType, cb as any);
  };

  const removeEventListener: MapProviderAdapter['removeEventListener'] = (
    event,
    cb,
  ) => {
    mapboxService.map?.off(event as MapEventType, cb as any);
  };

  const goToFloor = async (floorId: string) => {
    mapboxService.setFloor(floorId);
  };

  const startRoute: MapProviderAdapter['startRoute'] = async () => {};
  const stopRoute: MapProviderAdapter['stopRoute'] = async () => {};
  const addMarker: MapProviderAdapter['addMarker'] = async (poi) => {
    mapboxService.addMarker(poi);
    return Promise.resolve();
  };
  const removeMarker: MapProviderAdapter['removeMarker'] = async (poiId) => {
    mapboxService.removeMarker(poiId);
    return Promise.resolve();
  };

  const addMarkersGroup: MapProviderAdapter['addMarkersGroup'] = (markers) => {
    mapboxService.addMarkers(markers);

    return () => mapboxService.clearActiveMarkers();
  };

  return {
    init,
    goToPoi,
    search,
    fetchPoiById,
    getPoiById,
    getPois,
    resetView,
    terminate,
    addEventListener,
    removeEventListener,
    startRoute,
    stopRoute,
    addMarker,
    removeMarker,
    addMarkersGroup,
    goToFloor,
    isInitialized: () => mapboxService.isLoaded,
    getCurrentFloorId: mapboxService.getCurrentFloorId,
    getPoiIdFromMapMouseEvent: mapboxService.getPoiIdFromMapMouseEvent,
    updatePois: poiService.updatePois,
    updateYouAreHereMarkerElement: mapboxService.updateYouAreHereMarkerElement,
    removeYouAreHereMarker: mapboxService.removeYouAreHereMarker,
    updateYouAreHereMarkerLocation:
      mapboxService.updateYouAreHereMarkerLocation,
    centerMapToYouAreHereMarker: mapboxService.centerMapToYouAreHereMarker,
  };
};
